// eslint-disable-next-line no-redeclare,no-unused-vars
function buildChart(cont, param, xhr = {}) {
  var object = param.object;
  var key = param.key;
  var datasets = [];
  var labels = [];
  var colors = [
    'rgba(63, 81, 181, 0.5)',
    'rgba(77, 182, 172, 0.5)',
    'rgba(66, 133, 244, 0.5)',
    'rgba(156, 39, 176, 0.5)',
    'rgba(233, 30, 99, 0.5)',
  ];
  var pm = {};
  var chartType = param.chartType ? param.chartType : 'bar';

  // table attributes
  var table = {};
  table.ids = xhr.ids ? xhr.ids : param.ids;
  table.where = xhr.where ? xhr.where : {};
  table.filter = {};
  table.filterJs = {};
  if (view) view.table = table;
  param.table = table;

  // add data
  var addLineData = function (ids, label, color) {
    var values = [];
    $.each(ids, function (i, id) {
      var row = getData(object, id);
      values.push(row.yValue);
      var label = moment(row.xValue).format('MM/YY');
      // if (row.period == 'month') label = moment(row.xValue).format('MM/YY');
      if (!inArray(label, labels)) labels.push(label);
    });
    datasets.push({
      label: label,
      data: values,
      yAxisID: 'y',
      order: 2,
      backgroundColor: color,
    });
  };
  var addPieData = function (ids) {
    var amounts = {};
    labels = [];
    $.each(ids, function (i, id) {
      var value = getData(object, id, key);
      if (!value) return;
      if (amounts[value]) amounts[value]++;
      else {
        amounts[value] = 1;
        labels.push(value);
      }
    });
    amounts = Object.values(amounts);
    datasets.push({data: amounts, backgroundColor: colors});
  };

  // prepare pie parameter
  if (chartType == 'pie') {
    pm.dataLabelsPlugin = true;
    pm.options = {plugins: {datalabels: {color: 'white'}}};
    addPieData(table.ids, param.label);
  }

  // prepare default parameter
  else {
    pm.options = {
      scales: {
        y: {
          display: true,
          position: 'left',
        },
      },
    };
    if (xhr.dataDiagram)
      $.each(xhr.dataDiagram, function (i, diagram) {
        addLineData(diagram.data, diagram.label, colors[i]);
      });
    else addLineData(table.ids, param.label);
  }

  // create canvas
  var element = $('<canvas></canvas>').appendTo(cont);
  element.table = table;
  var chartObj = new mdb.Chart(
    element[0],
    {type: chartType, data: {labels: labels, datasets: datasets}},
    pm
  );

  // update handle
  element.add = function (row) {
    table.ids.push(row.id);
    element.update();
  };
  element.rem = function (row) {
    table.ids = arrayRemove(table.ids, row.id);
    element.update();
  };
  element.update = function () {
    if (chartType == 'line') return;
    if (chartType == 'pie') addPieData(table.ids);
    chartObj.update({labels, datasets: datasets}, {});
  };

  // registerTable(element, object, param);
  return element;
}
